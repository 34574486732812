<template>
  <div>
    <OrganismsGsdBar :vertical="vertical" />

    <molecules-hero-header
      :image-config="{
        img: `${APP_ASSET_BASEURL}edge/static/images/hero/energy_hero_images_v2_1x.webp`,
        class: 'max-w-full w-[28rem] lg:h-[23.44rem]',
        dimensions: {
          width: 448,
          height: 380
        }
      }"
      :buttons="[
        {
          theme: 'primary',
          text: 'GET A QUICK QUOTE',
          url: verticalData.formPage,
          crossApp: true
        }
      ]"
      img-alt="Feeling burnt by energy bills? Join The Club"
    >
      <template #header>
        Feeling burnt by
        <br />
        energy bills?
        <br />
        <span class="font-extrabold">Join The Club.</span>
      </template>

      <template #sub-header>
        You could be saving between $266 and $553 compared to the energy reference price.*<br />
        Use our
        <img
          class="inline w-24"
          :src="expertEaseImg"
          alt="Expert ease"
          width="96"
          height="17"
        />
        to cut the cost of your gas and electricity.
      </template>
    </molecules-hero-header>

    <molecules-home-great-deal
      :cards="GREAT_DEAL_ITEMS"
      :col-count="4"
      :button-config="{
        class: '!text-sm'
      }"
      card-class="p-6"
      card-wrapper-max-width="w-wrapper-lg"
      class="!bg-blue-5"
      image-lazy-load
    >
      <template #header>
        Welcome to <strong class="font-extrabold">Compare Club Energy</strong>
      </template>

      <template #sub-header>
        Here are some ways you can get the most out of your home energy plan.
      </template>
    </molecules-home-great-deal>

    <molecules-home-saving-plan
      :verticals="SAVING_PLAN_ITEMS"
      class="bg-white"
      card-image-class="mx-auto"
      layout="alternate-items"
      image-lazy-load
    >
      <template #header>
        Understand how <strong class="font-extrabold"><br class="md:hidden" />we can help you save.</strong>
      </template>
    </molecules-home-saving-plan>

    <molecules-home-talk-to-us
      class="bg-white pt-12 pb-10 lg:pt-0 lg:pb-20"
      theme="dark"
      :buttons="[
        {
          label: 'GET A QUOTE',
          to: verticalData.formPage,
          hasIcon: true,
          crossApp: true
        }
      ]"
      image-lazy-load
      hide-right-content-on-mobile
    >
      <template #header>
        Are you <strong class="font-extrabold">paying too much</strong> for<br />your electricity and gas?
      </template>

      <template #sub-header>
        Save time and money as we do the heavy lifting. Our expert team takes care of everything, from finding you the lowest possible price, to handling all your paperwork. We'll even apply any relevant discounts you're eligible
        for.
      </template>

      <template #right-content>
        <NuxtImg
          :src="`${APP_ASSET_BASEURL}edge/static/images/products/energy/expert.svg`"
          alt="Expert"
          width="259"
          height="248.9"
          class="m-auto pb-6"
          loading="lazy"
        />
      </template>
    </molecules-home-talk-to-us>

    <molecules-home-guide-tiles
      :guides="GUIDES_ITEMS"
      :action-button-url="VERTICALS[vertical].guidesUrl"
      image-lazy-load
    >
      <template #header>
        All your <strong class="font-extrabold">Energy questions answered</strong><br />
        with our in depth guides
      </template>
    </molecules-home-guide-tiles>

    <molecules-faq
      id="faq"
      :list="FAQ_ITEMS"
      :multiple-collapse="false"
      title="Electricity and Gas FAQs"
      class="bg-gray-10"
    >
      Here are some of the most frequently asked questions we receive about electricity and gas:
    </molecules-faq>

    <molecules-home-footer-info theme="white-boxed">
      <template #title>
        Things you should know
      </template>

      <template #description>
        <p>
          <NuxtLink
            class="text-primary underline"
            to="/"
          >
            CompareClub.com.au
          </NuxtLink>
          is an on-line energy comparison service and is owned and operated by Compare Club Australia Pty Ltd (ACN: 634 600 007).
          We have partnered with Zembl to provide you access to their expert staff to assist in selecting the most appropriate energy packages from their panel of providers.
          Compare Club doesn’t compare all products from all retailers and not all products are available to all customers.
          In providing a comparison, we don’t necessarily compare all features that may be relevant to you.
          Please consider the individual product terms and conditions carefully to ensure the product is right for you.
        </p>

        <p>
          * Savings are calculated compared to the Energy Reference price for a single-rate energy meter based on Compare Club’s best offer as of 25th July 2024.
        </p>
      </template>
    </molecules-home-footer-info>
  </div>
</template>

<script setup>
import {
  GREAT_DEAL_ITEMS,
  SAVING_PLAN_ITEMS,
  GUIDES_ITEMS,
  FAQ_ITEMS
} from '~/constants/pages/energy'

const vertical = VERTICAL.ENERGY
const verticalData = VERTICALS[vertical]
const $route = useRoute()

defineOptions({
  name: 'Energy'
})

generateMeta({
  title: 'Compare Electricity & Gas | Compare Club',
  ogTitle: 'Compare Electricity & Gas with Compare Club',

  description: 'Save time and money by easily finding a plan that cuts your costs. Compare leading energy companies and let us handle your transfer paperwork.',
  ogDescription: 'Save time and money by easily finding a plan that cuts your costs. Compare leading energy companies and let us handle your transfer paperwork.',

  ogUrl: 'https://compareclub.com.au/energy/',
  ogType: 'product'
}, pageIsFunnel($route))

useJsonld(() => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Energy provider comparison',
    image: 'https://compareclub.com.au/_ipx/s_190x30/images/cc-logo-new.svg',
    url: 'https://compareclub.com.au/energy/',
    description: 'We bring together a network of energy suppliers and compare their offered rates on different electricity and gas plans. We put the power in your hands to decide which price is the best and provide the switching service free of charge.',
    brand: {
      '@type': 'Brand',
      name: 'Compare Club'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      bestRating: '5',
      worstRating: '1',
      ratingCount: '3028'
    }
  }
})
</script>
